@import '../../../../common/styles/colors.scss';
.GraphGrid{
    flex: 1;
    height: 100%;
    padding: 1vh;
    .gridgraph{
        display: grid;
        height: 100%;
        grid-template-rows:repeat(8,1fr);
        grid-template-columns:repeat(8,1fr);
        gap:15px;
        .graph{
            grid-row:span 8;
            grid-column: span 8;
           
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 5px 0px rgba(217,217,217,1); 
           
        }
        .gridChild{
            width: 202px;
            height:34px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 0px 5px 0px rgba(217,217,217,1);  
            flex-direction: column;
            background-color: white;
            width: 100%;
            color: $nyc-success;
            text-align: center;
            font-weight: bold;
            font-size: 10px;
            .text{
                
                color: black
            }
        }
    } 
}