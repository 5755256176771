.ant-select-dropdown {
  width: 191px !important;
}

.orText {
  margin: 5px !important;
}

.trans {
  color: transparent;
  cursor: default;
}

.circleButton {
  border-radius: 50% !important;
  margin: 2px;

  &:hover {
    background-color: lightgray;
  }

  &:active,
  &:focus {
    background-color: transparent;
  }
}
.ant-select-selection--multiple {
  white-space: nowrap;
  height: 30px;
  overflow: auto;
}
