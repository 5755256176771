@import "../../../common/styles/fonts.scss";
@import "../../../common/styles/colors.scss";
.headerClass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1%;
  border-bottom: thin solid $nyc-light-grey;
  .logo {
    display: flex;
    justify-content: flex-start;
    .img {
    }
  }
  .text {
    font-size: 1.2vw;
    font-family: $Montserrat;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 12px;
    .help {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
