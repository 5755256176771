.FourOFour {
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: white;
    display: flex;
    flex-direction: column;
    .loader{
     
        align-items: center;
      
        justify-content: center;

    }
    .notFound {
      font-size: 28px;
      text-align: center;
      font-weight: bolder;
    }
    .notText{
        text-align: center;
        width: 80%
    }
  }
  