@import "../../../../common/styles/colors.scss";
@import "../../../../common/styles/fonts.scss";
.AntTable {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .disabledRow {
    background-color: $nycLightGrey;
  }
  .ant-table-wrapper {
    width: 100%;
  }
  .ant-table-container {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.26);
    .ant-table-body {
      min-height: calc(75vh - 15em);
    }
  }
  .ant-spin-nested-loading {
    .ant-spin {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }
  }
  .ant-table-placeholder {
    height: calc(75vh - 15em);
  }
  .ant-table {
    .idCell {
      justify-content: center;
      white-space: nowrap;
      display: inline-flex;
      width: 100%;
      .ellipsisPhone {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .ellipsisText {
        width: 60%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        flex: 1;
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .showOnHover {
        visibility: hidden;
        display: flex;
        align-items: center;
      }
      &:hover {
        .showOnHover {
          visibility: visible;
        }
      }
      .iconPoin {
        margin-left: 10px;
        cursor: copy;
      }
      .iconCopy {
        margin-left: 10px;
        cursor: copy;
        color: rgb(161, 161, 161);
      }
      .iconEdit {
        margin-left: 10px;
        cursor: pointer;
      }
      .actionIcon {
        cursor: pointer;
        color: black;
      }
    }
    .phoneNumber {
      cursor: url(https://img.icons8.com/metro/16/000000/edit.png) 8 8, pointer;
    }
    .address {
      display: flex;
      white-space: normal;
      align-items: center;
      .iconPoin {
        margin-left: 10px;
        cursor: copy;
      }
    }
    .ant-table-title {
      border: none;
      font-size: large;
      font-weight: bold;
    }
    .ant-table-thead {
      tr {
        th {
          background-color: $nyc-light-bg;
          color: black;
          text-align: center;
          white-space: nowrap;
        }
      }
    }
    .ant-table-tbody {
      tr {
        td {
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
  .pagination {
    margin-top: 10px;
    width: 100%;
    .ant-pagination {
      display: flex;
      justify-content: end;
      .ant-pagination-total-text {
        flex: 1;
      }
      .ant-pagination-prev {
        margin-right: 1rem;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -o-user-select: none;
        font-family: $Montserrat;
      }
      .ant-pagination-next {
        margin-left: 1rem;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -o-user-select: none;
        font-family: $Montserrat;
      }
    }
  }
}
.selected-row {
  background-color: rgba(63, 191, 189, 0.4);
}
.ActionsCSS {
  .ant-popover-content {
    .ant-popover-inner {
      .ant-popover-title {
        font-weight: bold;
      }
    }
  }
}
// .ant-table-tbody > tr.ant-table-row:hover > td {
//   background: unset;
// }
.list {
  list-style: none;
  padding: 0px;
  li {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: $nyc-light-bg;
    }
  }
  .noHover {
    &:hover {
      background-color: transparent;
    }
  }
  span {
    display: flex;
    align-items: center;
    margin: 5px;
  }
}
.ant-modal-header {
  text-align: center;
}
.ant-form-item-explain-error {
  font-size: 12px;
}
.textTool {
  &::before {
    content: "";
  }
  &::after {
    content: " copied";
  }
}
.tabButtons {
  background-color: $nyc-success;
  color: white;
  box-shadow: none;
  border: 0px;
  &:hover {
    color: white;
    border: none;
    box-shadow: none;
    background: $nyc-success;
  }
  &:focus {
    color: white;
    border: none;
    background: $nyc-success;
  }
}
.Toastify__toast {
  font-family: "Montserrat", sans-serif !important;
}
.ant-pagination-total-text {
  margin-right: auto;
}
.ant-pagination-total-text {
  flex: 1;
}
.ant-pagination-prev {
  margin-right: 1rem !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  font-family: $Montserrat;
  a {
    color: $nyc-success;
  }
  a[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}

.ant-pagination-next {
  margin-left: 1rem !important;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  font-family: $Montserrat;
  a {
    color: $nyc-success;
  }
  a[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}
.ant-pagination-item-active {
  a {
    color: $nyc-success;
  }
  border-color: $nyc-success;
}
.ant-pagination-item:hover a {
  color: $nyc-success;
}
.ant-pagination-item-link-icon {
  color: $nyc-success !important;
}
.ant-table-filter-trigger {
  &:hover {
    color: black;
  }
  color: black;
}
.active {
  color: $nyc-success !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $nyc-success;
  border-color: $nyc-success;
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
  color: $nyc-success;
}
.spinner {
  animation: spin infinite 1s linear;

  /*You can increase or decrease the timer (5s) to 
     increase or decrease the speed of the spinner*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
