@import '../../common/styles/colors.scss';
@import '../../common/styles/fonts.scss';

.quoteText{
    font-family: $Montserrat;
    font-size: "5px";
    color: $nyc-grey;
}

  .dot:nth-child(1) {
    animation-delay: 0.1s;
  }
  
  .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dot:nth-child(3) {
    animation-delay: 0.3s;
  }
  .dot:nth-child(4) {
    animation-delay: 0.3s;
  }
  .dot:nth-child(5) {
    animation-delay: 0.3s;
  }

  @keyframes dotFlashing {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  