$white:#ffffff;
$nyc-light-bg: #e5f7f8;
$nyc-white: #f4f4f4;
$nyc-pure-white: #ffffff;
$nyc-warning: #fdb933;
$nyc-success: #00b2b9;
$nyc-failed: #dc5e5e;
$nyc-light-grey: #e9e9e9;
$nyc-grey: #323031;
$nyc-black: #000000;
$disableGrey: #adadad;
$inputBGGrey: #e8e8e8;
$nycDullGrey: #ececec;
$nycLightGrey: #f8f8f8;

$nyc-calender-blue: #d8fbf0;
$nyc-calender-border: #adadad;
$titleFont:1vw;
$bigFont:0.9vw;
$smallFont:0.7vw;
// .font {
//   font-size: ;
// }
// .smallFont {
//   font-size: 0.7vw;
// }
// .clinicFont {
//   font-size: 0.9vw;
// }
// $nyc-calender-border :#adced3;
// $color:#FDB933
// #00B2B9
// #DC5E5E
// #323031
// #ECECEC
// #F8F8F8
// #E5F7F8
// #F4F4F4
// #FFFFFF
// #DDDDDD
// #B2E8EA



$title-bg :#E5F7F8;
$line1-bg :#F4F4F4;
$line2-bg :#FFFFFF;
$pending  :#FDB933;
$approved :#00B2B9;
$denied   :#DC5E5E;
$sider-bg :#323031;
$toast-success:#00B2B9;
$toast-failuer:#DC5E5E;

.nyc-grey {
  color: $nyc-grey;
}

.nyc-light-bg {
  color: $nyc-light-bg;
}

.nyc-white {
  color: $nyc-white;
}

.nyc-pure-white {
  color: $nyc-pure-white;
}

.nyc-warning {
  color: $nyc-warning;
}

.nyc-success {
  color: $nyc-success;
}

.disableGrey {
  color: $disableGrey;
}
