@import "antd/dist/antd.css";
@import "./common/styles/colors.scss";
@import "./common/styles/fonts.scss";
.App {
  min-height: 100vh;
}
.ant-select-dropdown {
  width: 191px !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
// .ant-popover-arrow{display: none !important;}
.ant-popover-placement-bottom {
  padding-top: 0px !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.ant-btn-primary {
  background-color: $nyc-success !important;
  border-color: $nyc-success !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-table-filter-column {
  justify-content: center !important;
}
.ant-table-filter-dropdown {
  .ant-table-filter-dropdown-btns {
    button {
      background-color: $nyc-success;
    }
    .ant-btn-link {
      background-color: transparent;
      span {
        color: black;
      }
      &:disabled {
        background-color: transparent;
        span {
          color: grey;
        }
      }
    }
  }
}
.toastBody {
  font-family: "Montserrat", sans-serif;
}
.ant-select-disabled {
  color: black !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: black !important;
}
.ant-select-selection-search-input {
  font-family: $Montserrat !important;
}
