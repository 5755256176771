.barGraph {
  height: 100%;
  width: 100%;
  height: 500px;
  width: 1500px;
  .spinner {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
