@import "../../../common/styles/colors.scss";
.tableGraph {
  margin: 1%;
  flex: 1;
  height: 100%;
  margin-top: 0;
  .tableTab {
    outline: none;
  }

  .Tabicon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
  }
  .ant-tabs {
    height: 100%;
    .ant-spin-nested-loading > div {
      height: 100%;
    }
    .ant-tabs-content {
      height: 100%;
    }
  }
  .ant-tabs-nav::before {
    border-bottom: 0px;
  }

  .ant-tabs-content-holder {
    padding: 1px;
    height: 100%;
  }
  .ant-tabs-nav {
    display: flex;
    justify-content: flex-start;
    margin: 0.5rem 0.5rem 0.5rem 0;
  }

  .ant-tabs-nav {
    .ant-tabs-tab {
      margin: 0;
      padding: 5px 10px;
    }
    .ant-tabs-extra-content {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
    .ExtraButtons {
      display: flex;
      gap: 5px;
      width: 100%;
      align-items: center;
      justify-content: end;
      .tabButtons {
        background-color: $nyc-success;
        color: white;
        box-shadow: none;
        border: 0px;
        &:hover {
          color: white;
          border: none;
          box-shadow: none;
        }
        &:focus {
          border: none;
        }
      }
      .tags {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
      }
      @media screen and (max-width: 1500px) {
        .tags {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
    .Icons {
      font-size: 16px;
      font-weight: bold;
      color: $nyc-success;
      cursor: pointer;
      flex: 1;
      width: 100%;
      justify-content: end;
    }
    .ant-tabs-nav-wrap {
      flex: none;
      padding: 5px;
      justify-content: right;

      .ant-tabs-nav-list {
        padding: 5px;
        background-color: $nyc-light-grey;
      }
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $nyc-white;
  }

  .ant-tabs-ink-bar {
    background: none;
  }

  .ant-tabs-tab-active {
    background-color: $nyc-success;
    // padding         : 1rem;
  }
}
