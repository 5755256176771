@import "../../../common/styles/colors.scss";
.FilteMain {
  background-color: white;
  display: flex;
  height: 100%;
  width: 100%;
  margin-left: 1%;
  margin-right: 1%;
  .tabButtons {
    background-color: $nyc-success;
    color: white;
    box-shadow: none;
    border: 0px;
    width: 100px;
    display: flex;
    justify-content: center;
    &:hover {
      color: white;
      border: none;
      box-shadow: none;
      background: $nyc-success;
    }
    &:focus {
      color: white;
      border: none;
      background: $nyc-success;
    }
  }
  .row {
    width: 100%;
    height: 100%;
    .fields {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      .IconsFilter {
        display: flex;
        justify-content: space-around;
        flex: 1;
        width: 100%;
      }
    }
    .Date {
      display: flex;

      width: 100%;
      align-items: center;
      justify-content: end;
    }
  }
  .download {
    display: flex;
    width: 100%;
    .icon {
      cursor: pointer;
    }

    color: $nyc-success;
    align-items: center;
    justify-content: center;
  }
 
}
.ant-select-multiple .ant-select-selection-search-input{
  min-width: 120px;
  width: 100%;
}