@import '../../common/styles/colors.scss';
@import '../../common/styles/fonts.scss';
.dashboardGrid{
    background-color: $white;
    height: 100vh;
    display:flex;
    width: 100%;
    flex-direction: column;
    font-family: $Montserrat !important;
    overflow: hidden;
    .header{
        padding: 20px;
        padding-bottom: 15px;
        padding-top: 15px;
        top: 0;
        z-index: 99;
        background: white;
        box-shadow:0px 8px 8px -9px rgb(0 0 0 / 26%);
    }
    .tableAndGraph{
        flex:1;
        height: 100%;
        padding: 20px;
        padding-top: 0px;
        overflow: hidden;
               
    }
    .tableAndGraph::-webkit-scrollbar {
        display: none;
      }
}